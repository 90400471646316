<template>
    <div class="answer-container py-2">
        <v-card outlined class="pa-4">
            <div>
                <v-img v-if="isImage(question)" :src="imageContentBuilder(question)" contain class="question-img"></v-img>
                <span v-else>{{ question.descripcion }}</span>
            </div>
            <v-divider></v-divider>
            <div class="answers pl-10">
                <div v-if="isMultiple" :class="{'show-like-a-flex-row': hasImages(question.respuestas)}">
                    <v-checkbox
                        v-for="(answer, i) of question.respuestas"
                        :key="i"
                        v-model="selected"
                        :value="answer.orden"
                        color="primary"
                        multiple
                        @change="setAnswer"
                    >
                        <template v-slot:label>
                            <span class="font-weight-black orange--text text--darken-4 mx-2 title answer-label">{{ `${answer.orden}) ` }}</span>
                            <v-img v-if="isImageRespuesta(answer)" :src="imageContentBuilder(answer)" contain></v-img>
                            <span v-else>{{ answer.descripcion }}</span>
                        </template>
                    </v-checkbox>
                </div>
                <div v-else>
                    <v-radio-group v-model="selected" @change="setAnswer" column>
                        <v-radio
                            v-for="(answer, i) of question.respuestas"
                            :key="i"
                            :value="answer.orden"
                            color="primary"
                        >
                            <template v-slot:label>
                                <span class="font-weight-black orange--text text--darken-4 mx-2 title answer-label">{{ `${answer.orden}) ` }}</span>
                                <v-img v-if="isImageRespuesta(answer)" :src="imageContentBuilder(answer)" contain></v-img>
                                <span v-else>{{ answer.descripcion }}</span>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <v-btn secondary text color="grey" @click="clearAnswers">{{$t('modules.exams.assestment.answers.clearAnswersButton')}}</v-btn>
                </div>
            </div>
        </v-card>
        <div class="buttons-section pt-5">
            <v-btn
                large
                rounded
                color="primary"
                class="ml-3"
                :loading="saving"
                :disabled = "readOnly"
                @click="saveAnswers(false)"
            >
                {{ $t('modules.exams.assestment.answers.button') }}
            </v-btn>
            <v-checkbox
                v-model="toReview"
                :label="$t('modules.exams.assestment.answers.review')"
                color="secondary"
                class="ml-5"
                :disabled="readOnly"
            ></v-checkbox>
        </div>
        <AlertPopup
            :active="alert.active"
            :title="alert.title"
            :content="alert.content"
            :actionPrimary="alert.actionPrimary"
            :actionSecondary="alert.actionSecondary"
            :icon="alert.icon"
            :color="alert.color"
        ></AlertPopup>
    </div>
</template>

<script>
    import { cloneDeep, isEqual, sortBy } from 'lodash';
    import moment from 'moment'
    import Constants from '@/plugins/constants'
    import AlertPopup from './AlertPopup'


    export default {
        name: 'Andswers',
        props: {
            readOnly:{type:Boolean, default:false},
            question: {type: Object, required: true},
            currentTime: {type: Number, required: true},
            examId: {type: Number},
        },
        components: {
            AlertPopup,
        },
        data () {
            return {
                selected: [],
                saving: false,
                toReview: false,
                originalAnswers: null,
                imageContent: false,
                alert: {
                    active: false,
                    title: null,
                    content: null,
                    actionPrimary: null,
                    actionSecondary: null,
                    icon: { color: 'warning', name: 'mdi-eye-off-outline' },
                    color: 'primary',
                }
            }
        },
        watch: {
            question (value) {
                this.selected = [];
                this.toReview = value.status.toReview;
                this.checkAnsweredQuestions();
            }
        },
        computed: {
            isMultiple () {
                return [76,5].includes(this.question.tipoPreguntaId)
            },
        },
        methods: {
            isImage (answer) {
                return !!answer?.media?.imagen  // Es una pregunta.
            },
            hasImages (respuestas) {
                let textOnly = false;
                respuestas.forEach(function(answer) {
                    if (answer?.media?.imagen) {
                        textOnly = true;
                    }
                });

                return textOnly;
            },
            isImageRespuesta (answer) {
                // Es una pregunta.
                return !!answer?.media?.imagen;
            },
            imageContentBuilder (answer) {
                const extension = answer.media.descripcion.split('.').pop();
                return `data:image/${extension.toLowerCase()};base64,${answer.media.imagen}`;
            },
            setAnswer () {
                this.question.respuestas.forEach((answer) => {
                    if (Array.isArray(this.selected) && this.selected.includes(answer.orden)) answer.elegida = 'S';
                    else if (answer.orden === this.selected) answer.elegida = 'S';
                    else answer.elegida = 'N';
                });

                this.$emit('update:question', this.question);
            },
            async saveAnswers (onlySave = false) {
                this.saving = true;
                if (this.withoutAnswers(this.question.respuestas)) {
                    this.displayWarningPopUp(async () => {
                        this.question.status.answered = false;
                        if (!isEqual(sortBy(this.originalAnswers), sortBy(this.question.respuestas))) await this.sendToBackend(onlySave);
                        this.alert.active = false
                        this.saving = false;
                        if (!onlySave) this.$emit('next-question', true)
                    }, () => {
                        this.alert.active = false
                        this.saving = false;
                    });
                } else {
                    this.$emit('saving-answers', true);
                    this.question.status.answered = true;
                    this.question.status.toReview = this.toReview;
                    this.question.isRevisar = this.question.status.toReview ? 'S' : 'N';

                    try {
                        await this.sendToBackend(onlySave);
                        this.saving = false;
                        if (!onlySave) this.$emit('next-question', true);
                        this.$emit('saving-answers', false);
                    } catch (error) {
                        this.alert.title = this.$t('modules.exams.assestment.popUp.errorSavingAnswer.title')
                        this.alert.content = this.$t('modules.exams.assestment.popUp.errorSavingAnswer.content')
                        this.alert.actionPrimary = {
                            text: this.$t('modules.exams.assestment.popUp.errorSavingAnswer.buttonPrimary'),
                            callback: () => {
                                this.alert.active = false
                                this.saving = false
                                if (!onlySave) this.$emit('next-question', true)
                                this.$emit('saving-answers', false)
                            },
                        }
                        this.alert.actionSecondary = {
                            text: this.$t('modules.exams.assestment.popUp.errorSavingAnswer.buttonSecondary'),
                            callback: () => {this.alert.active = true; this.saving = false; this.$emit('saving-answers', false)},
                        }
                        this.icon = { color: 'error', name: 'mdi-cancel' },
                        this.alert.active = true
                    }
                }
            },
            async sendToBackend (onlySave = false) {
                const savedQuestion = {
                    tiempoRestante: 0,
                    enunciado: null,
                };

                const {status, ...enunciado} = this.question;
                savedQuestion.enunciado = enunciado;
                savedQuestion.tiempoRestante = moment.duration(this.currentTime).minutes();

                try {
                    await fetch(`${Constants.URL_BASE}/q-system/exams/${this.examId}/answers`, {
                        method: 'POST',
                        mode: 'cors',
                        body: JSON.stringify(savedQuestion),
                        headers: {
                            'Authorization': 'Basic cG9ydGFsLXRlY2xhYjpUaDEkSXNNeVN1cGVyU2VjcjN0',
                            'Content-Type': 'application/json'
                        },
                    })
                } catch (error) {
                    let tmpAnswers = [];

                    if (window.localStorage.getItem('questions')) {
                        tmpAnswers = JSON.parse(window.localStorage.getItem('questions'));
                    }

                    tmpAnswers.push(savedQuestion);
                    window.localStorage.setItem('questions', JSON.stringify(tmpAnswers));
                    this.alert.title = this.$t('modules.exams.assestment.popUp.errorSavingAnswer.title')
                    this.alert.content = this.$t('modules.exams.assestment.popUp.errorSavingAnswer.content')
                    this.alert.actionPrimary = {
                            text: this.$t('modules.exams.assestment.popUp.errorSavingAnswer.buttonPrimary'),
                            callback: () => {
                                this.alert.active = false
                                this.saving = false
                                if (!onlySave) this.$emit('next-question', true)
                                this.$emit('saving-answers', false)
                            },
                        }
                    this.alert.actionSecondary = {
                        text: this.$t('modules.exams.assestment.popUp.errorSavingAnswer.buttonSecondary'),
                        callback: () => {this.alert.active = true; this.saving = false; this.$emit('saving-answers', false)},
                    }
                    this.icon = { color: 'error', name: 'mdi-cancel' },
                    this.alert.active = true
                    throw error;
                }
            },
            withoutAnswers (answers) {
                return answers.reduce((acc, answer) => {
                    if (answer.elegida.toLowerCase() === 's') return false;
                    return acc;
                }, true);
            },
            clearAnswers () {
                this.question.respuestas.forEach((answer) => { answer.elegida = 'N' });
                this.selected = [];
            },
            checkAnsweredQuestions () {
                if (this.isMultiple) {
                    this.question.respuestas.forEach((answer) => {
                        if (answer?.elegida?.toLowerCase() === 's') {
                            this.selected.push(answer.orden);
                            this.question.status.answered = true;
                        }
                    });
                } else {
                    this.question.respuestas.forEach((answer) => {
                        if (answer?.elegida?.toLowerCase() === 's') {
                            this.selected = answer.orden;
                            this.question.status.answered = true;
                        }
                    });
                }
            },
            displayWarningPopUp (actionPrimaryCallBack, actionSecondaryCallback) {
                this.alert.title = this.$t('modules.exams.assestment.popUp.withoutAnswers.title')
                this.alert.content = this.$t('modules.exams.assestment.popUp.withoutAnswers.content')
                this.alert.actionPrimary = { text: this.$t('modules.exams.assestment.popUp.withoutAnswers.buttonPrimary'), callback: actionPrimaryCallBack }
                this.alert.actionSecondary = { text: this.$t('modules.exams.assestment.popUp.withoutAnswers.buttonSecondary'), callback: actionSecondaryCallback }
                this.icon = { color: 'warning', name: 'mdi-eye-off-outline' }
                this.color = 'primary'
                this.alert.active = true
            },
        },
        mounted () {
            this.originalAnswers = cloneDeep(this.question.respuestas);
            this.checkAnsweredQuestions();
        }
    }
</script>

<style lang="sass" scoped>
.show-like-a-flex-row
  width: 100%
  display: flex
  flex-direction: column
  flex-wrap: wrap
  justify-content: flex-start
  align-items: flex-start
  align-content: flex-start

  v-input--checkbox
    width: fit-content

.answer-label
  font-size: 20px

.answer-container
  .v-card
    .title
      font-size: 16px
      font-weight: bold
      color: var(--v-primary-base)

  .buttons-section
    display: flex
    flex-direction: row
    align-items: center

.question-img
  width: fit-content
  display: inline-block

</style>
